import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "../../../environments/environment";
import { User } from '../models/user.model';

@Injectable()

export class AuthService {

    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;

    secreatUserName:any="home#00";
    secreatPassword:any="Home$ell@7&*098";


    private emailSend = new Subject<any>();
    child1$ = this.emailSend.asObservable();


    SendToChild1(msg:string){
        this.emailSend.next(msg);
    }

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) { }

    public get currentUserValue() {
        let data = localStorage.getItem('currentUser');
        let obj: Object = {};
        if (data !== 'undefined')
            return JSON.parse(localStorage.getItem('currentUser'));
        return false;
    }

    
    public get basicAuthentication() {
        let data = {
            userName: this.secreatUserName,
            password: this.secreatPassword
        }
        return data;
    }

    login(user: User) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + window.btoa(this.basicAuthentication.userName + ':' + this.basicAuthentication.password)
            })
        };
        return this.http.post<any>(this.url + 'admin-user/login', user,httpOptions);
    }

    reset(data: any) {
        return this.http.post(this.url + 'user/reset-password',data);
    }

    getAdminLogo(id:any){
        return this.http.get(this.url + `admin-user/logo/${id}`);
    }


    forgetPassword(data: any) {
        return this.http.post(this.url + 'admin-user/forgot-password',data);
    }    

    verifyPasswordToken(data: any) {
        return this.http.post(this.url + 'admin-user/verify-password-token',data);
    }    

    resetPassword(data: any) {
        return this.http.post(this.url + 'admin-user/reset-password',data);
    }    

    logout() {
        return this.http.get(this.url + 'auth/logout');
    }

    updateuser(data: any) {
        return this.http.patch(this.url + 'user/update', data);
    }

    changePassword(data: any) {
        return this.http.post(this.url + 'admin-user/change-password', data);
    }

    emailValidation(data:any){
        return this.http.post(this.url + "user/emailExists", data);
    }


}